<template>
  <v-container class="pa-0 ma-0">
    <v-row class="pa-0 ma-0">
      <v-col v-if="false" class="pt-0 mt-0" cols="12">
        <v-row class="pt-0 mt-0">
          <v-col cols="12" lg="3" md="6" sm="12">
            <b-form-input v-model="search" :data-layout="KEYBOARD.KEYSETS.NORMAL"
                          :placeholder="$t('datatables.lang_search')+' ...'"
                          size="sm" @focus="showTouchKeyboard"/>
          </v-col>
          <v-col cols="12" lg="3" md="6" sm="12">
          </v-col>

          <v-col cols="12" lg="3" md="6" sm="12">
            <b-form-select v-model="status" :placeholder="$t('generic.lang_status')" se size="sm">
              <b-form-select-option :value="-1">{{ $t('generic.lang_allStatuses') }}</b-form-select-option>
              <b-form-select-option :value="1">{{ $t('generic.lang_confirmed') }}</b-form-select-option>
              <b-form-select-option :value="0">{{ $t('generic.lang_unconfirmed') }}</b-form-select-option>
            </b-form-select>
          </v-col>

          <v-col cols="12" lg="3" md="6" sm="12">
            <b-form-select v-model="source" :placeholder="$t('generic.lang_sources')" se size="sm">
              <b-form-select-option :value="-1">{{ $t('generic.lang_allSources') }}</b-form-select-option>
              <b-form-select-option value="phone">{{ $t('generic.lang_viaPhone') }}</b-form-select-option>
              <b-form-select-option value="online">{{ $t('generic.lang_online') }}</b-form-select-option>
            </b-form-select>
          </v-col>
        </v-row>
      </v-col>
      <v-col class="pa-0" cols="12">
        <v-card elevation="0" outlined tile>
          <v-card-text class="ma-0 pt-4 pa-0">
            <v-card elevation="0">
              <v-card-title class="pa-0 ma-0">
                <v-row>
                  <v-col class="pt-0 ma-0" cols="12">
                    <div class="card-header-tab card-header">
                      <div class="card-header-title font-size-lg text-capitalize font-weight-normal">
                        <b-input-group width="100%">
                          <b-form-input :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                        :placeholder="$t('datatables.lang_search')"
                                        @focus="showTouchKeyboard"
                                        v-model="search"/>
                        </b-input-group>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-card-title>

              <v-data-table
                  :footer-props="footerProps"
                  :headers="filteredDatatableHeaders"
                  :items="filteredDatatableEntries"
                  :loading="loading"
                  sortBy="position" selectable-key="id"
                  :options.sync="pagination"
                  :server-items-length="total"
                  footer-props.items-per-page-text="Einträge pro Seite"
                  v-model="selectedRows"
              >
                <template v-slot:body="props">
                  <draggable
                      :list="props.items"
                      tag="tbody"
                      v-bind="dragOptions"
                      @end="changePosition($event)"
                  >
                    <tr
                        v-for="(event, index) in props.items"
                        :key="index"
                    >

                      <td v-show="false">
                        {{ event.id }}
                      </td>
                      <td>
                        <font-awesome-icon :icon="['fas','grip-vertical']" style="color:lightgray;cursor: pointer;"
                                           class="page__grab-icon text-lighter handle"/>
                      </td>
                      <td>
                        <v-avatar tile width="100%" height="100%">
                          <img :src="event.image" :alt="event.eventName" style="width: 100%;height: auto">
                        </v-avatar>
                      </td>
                      <td> {{ event.eventName }}</td>
                      <td>
                        {{ event.shortDescription }}
                      </td>
                      <td>
                        <v-checkbox dense hide-details readonly disabled v-model="event.eventFeat"/>
                      </td>

                      <td>
                        <v-chip :color="event.isBookableSameDay ? 'success' : 'error'" text-color="white">
                          {{ event.isBookableSameDay ? $t('generic.lang_yes') : $t('generic.lang_no') }}
                        </v-chip>
                      </td>
                      <td>
                        {{ event.location }}
                      </td>
                      <td>
                        <v-switch v-model="event.status" true-value="1" false-value="0" dense inset
                                  @change="changeStatus(event)">

                        </v-switch>
                      </td>
                      <td>
                        <v-row align="center" justify="end">
                          <v-btn class="mx-auto" color="primary" icon text @click="editEntry(event)">
                            <v-icon> mdi-pencil</v-icon>
                          </v-btn>

                          <v-btn class="mx-auto" color="error" icon text @click="deleteEntry(event)">
                            <v-icon> mdi-delete</v-icon>
                          </v-btn>
                        </v-row>
                      </td>
                    </tr>
                  </draggable>
                </template>
              </v-data-table>
            </v-card>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <CreateEventComponent v-model="dialog" @close="edit=false; dialog=false" :uuid="uuid" :edit="edit"
                          @reloadData="getDataFromApi"/>
    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard v-if="touchKeyboard.visible" id="onScreenKeyboard"
                          :accept="hideTouchKeyboard"
                          :cancel="hideTouchKeyboard" :defaultKeySet="touchKeyboard.keySet"
                          :input="touchKeyboard.input" :layout="touchKeyboard.layout"
                          :options="touchKeyboard.options" class="internalWidthExpanded"/>
    </div>
  </v-container>
</template>

<script>
import {ENDPOINTS} from "@/config";
import {Events} from "@/plugins/events";
import mixin from "@/mixins/KeyboardMixIns";
import draggable from "vuedraggable";
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {library} from '@fortawesome/fontawesome-svg-core';
import {
  faAt,
  faCalendar,
  faCalendarAlt,
  faCalendarEdit,
  faCalendarTimes,
  faCheck,
  faCheckSquare,
  faClipboard,
  faClock,
  faCopy,
  faEdit,
  faEnvelope,
  faHashtag,
  faInfoSquare,
  faKeynote,
  faPhone,
  faStickyNote,
  faTrash,
  faUser,
  faUsers,
  faWindowClose,
} from '@fortawesome/pro-light-svg-icons';

import {faGripVertical} from '@fortawesome/pro-solid-svg-icons'
import CreateEventComponent from "./CreateEventComponent";


library.add(faUsers, faGripVertical, faClipboard, faClock, faCalendarAlt, faKeynote, faAt, faCalendar, faUser, faPhone, faCopy, faCheckSquare, faTrash, faInfoSquare, faCalendarEdit, faCalendarTimes, faWindowClose, faEnvelope, faEdit, faStickyNote, faCheck, faHashtag );

export default {
  name: "EventsListComponent",
  mixins: [mixin],
  components: {
    CreateEventComponent, draggable,
    'font-awesome-icon': FontAwesomeIcon,
  },
  data() {
    return {
      edit: false,
      dialog: false,
      tabs: false,
      type: -1,
      source: -1,
      status: -1,
      loading: false,
      drag: false,
      selectedRows: [],
      awaitingSearch: null,
      dataToShow: [],
      search: '',
      total: 10,
      pagination: {},
      footerProps: {'items-per-page-options': [15, 30, 50, 100]},
      rowsPerPageItems: [10, 20, 50, 100],
      uuid: null,
    }
  },
  computed: {
    datatableHeaders(){
      return[
        {
          text: '',
          value: 'id',
          hidden: true,
          sortable: false,
        },
        {
          text: '',
          value: 'position',
          sortable: false,
        },
        {
          text: '',
          value: 'image',
          sortable: false,
          width: 160,
        },
        {
          text: this.$t('generic.lang_name'),
          value: 'eventName',
          width: 120,
          sortable: false,
        },
        {
          text: this.$t('generic.lang_shortDescription'),
          value: 'shortDescription',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t("eventbee.lang_featured"),
          value: 'eventFeat',
          align: 'center',
          width: 40,
          sortable: false,
        },
        {
          text: this.$t("eventbee.lang_isBookableSameDay"),
          value: 'isBookableSameDay',
          align: 'center',
          width: 40,
          sortable: false,
        },
        {
          text: this.$t('generic.lang_location'),
          value: 'location',
          align: 'center',
          sortable: false,

        },
        {
          text: this.$t('generic.lang_status'),
          value: 'status',
          sortable: false,
        },
        {
          text: this.$t('generic.lang_position'),
          value: 'position',
          sort: "asc",
          sortable: false,
        },
        {
          text: '',
          value: 'crud',
          sortable: false,
          width: '120',
          align: 'end',
        }
      ]
    },
    filteredDatatableHeaders: function () {
      let headers = [];

      this.datatableHeaders.forEach((header) => {
        if (header.hasOwnProperty("hidden")) {
          if (header.hidden) {
            return;
          }
        }
        headers.push(header);
      });

      return headers;

    },
    filteredDatatableEntries: function () {
      let dataToRender = [];
      if (this.dataToShow)
        this.dataToShow.forEach((data) => {
          let row = {};
          let index = 0;
          this.datatableHeaders.forEach((header) => {
            if (header.value !== 'crud') {
              if (header.value === 'position') {
                row[header.value] = parseInt(Number(data[index]));
              } else {
                row[header.value] = data[index];
              }
            } else {
              return
            }
            index++;

          });
          dataToRender.push(row);
        });

      return dataToRender;
    },
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    }
  },

  mounted() {
    this.getDataFromApi();
  },
  watch: {
    search() {
      clearTimeout(this.awaitingSearch);

      this.awaitingSearch = setTimeout(() => {
        if (this.pagination.page !== 1) {
          this.pagination.page = 1;
        } else {
          this.getDataFromApi();
        }
      }, 500);
    },
    pagination: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
    selectedRows: function () {
      this.$emit("changeSelectedRows", this.selectedRows);
    },
    status(val) {

    },
    source(val) {

    },
    type(val) {

    },
    requested_date(val) {

    }
  },
  methods: {
    editEntry(entry) {
      this.edit = true;
      this.dialog = true;
      this.uuid = entry.id;
    },

    deleteData(idsToDelete = []) {

      this.$swal({
        title: this.$t('eventbee.lang_deleteEventTitle'),
        text: this.$t('eventbee.lang_deleteEventText'),
        icon: "warning",
        cancelButtonText: this.$t('generic.lang_cancel'),
        confirmButtonText: this.$t('generic.lang_delete'),
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          if (!idsToDelete || !idsToDelete.length || idsToDelete.length === 0) {
            idsToDelete = [];
          }
          this.axios.post(ENDPOINTS.EVENTBEE.EVENT.DELETE, {
            UUID: idsToDelete,
          }).then((res) => {
            if (res.status === 200) {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_success'),
                color: "success"
              });
              this.getDataFromApi();
              this.resetSelectedRows();
            } else {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_errorOccurred'),
                color: "error"
              });
            }
          });
        },
        allowOutsideClick: () => !this.$swal.isLoading,
      });
    },

    deleteEntry(entry) {
      this.deleteData([entry.id]);
    },
    deleteEntries() {
      let ids = []
      this.selectedRows.forEach((row) => {
        ids.push(row.id);
      });
      this.deleteData(ids)
    },
    changeStatus(entry) {
      this.axios.post(ENDPOINTS.EVENTBEE.EVENT.STATUS, {
        uuid: entry.id,
        status: entry.status == "1"
      }).then(res => {
        if (res.data.status) {
          this.getDataFromApi();
        } else {
          Events.$emit("showSnackbar", {
            color: "error",
            message: res.data.msg || res.data.status,
          });
        }
      }).catch(err => {
        Events.$emit("showSnackbar", {
          color: "error",
          message: err.message
        });
      })
    },
    changePosition(event) {

      let item = this.filteredDatatableEntries[event.oldIndex];
      let itemReplaced = this.filteredDatatableEntries[event.newIndex]

      if (item.id !== itemReplaced.id) {
        this.axios.post(ENDPOINTS.EVENTBEE.EVENT.POSITION, {
          uuid: item.id,
          position: itemReplaced.position,
        }).then(res => {
          if (res.data.status) {
            this.getDataFromApi();
          } else {
            Events.$emit("showSnackbar", {
              color: "error",
              message: res.data.msg || res.data.status,
            });
          }
        }).catch(err => {
          Events.$emit("showSnackbar", {
            color: "error",
            message: err.message
          });
        })
      }

    },
    resetSelectedRows() {
      this.selectedRows = [];
    },
    getDataFromApi() {
      this.loading = true;
      this.selectedRows = [];

      let params = {
        sEcho: 1,
        iDisplayStart: this.pagination.itemsPerPage * (this.pagination.page - 1),
        iDisplayLength: this.pagination.itemsPerPage
      };

      //SORTING / PAGINATION / SEARCHING
      if (this.search) {
        params.sSearch = this.search;
      }

      if (this.pagination.sortBy) {
        //FIND COL. INDEX
        let index = this.datatableHeaders.findIndex(header => header.value === this.pagination.sortBy[0]);
        index -= 1
        if (index < 0) {
          index = 0;
        }

        params["iSortingCols"] = 1;
        params["iSortCol_1"] = 1;
        params["bSortable_1"] = "true";
        params["sSortDir_1"] = 'asc';
      }

      /* SPECIFY ADDITIONAL PARAMS
      * params['customerID'] = parseInt(this.$props.customerId);
      *
      * */

      this.axios.get(ENDPOINTS.DATATABLES.EVENTBEE.EVENTS, {
        params: params
      }).then((res) => {

        this.total = parseInt(Number(res.data.iTotalDisplayRecords));
        this.dataToShow = res.data.aaData;
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          color: "error",
          message: err.message
        });
        this.dataToShow = [];
      }).finally(fin => {
        this.loading = false;
      })
    },
  }
}
</script>

<style scoped>

.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>
