<template>
	<v-dialog v-model="visible" max-width="600px" scrollable>
		<v-card height="500">
			<v-card-title class="card-header">
				{{ $t("generic.lang_bulkMailing") }}
			</v-card-title>
			<v-card-text class="mt-2">
				<v-form style="width: 100%" class="mt-1" ref="bulkEmailForm" v-model="valid" lazy-validation>
					<v-row no-gutters>
						<v-col cols="12" sm="8" md="9">
							<v-text-field
								v-model="subject"
								:data-layout="KEYBOARD.KEYSETS.NORMAL"
								:disabled="loading"
								:label="$t('settings.lang_mail_subject')"
								outlined
								dense
								:rules="[(v) => !!v]"
								@focus="showTouchKeyboard"
							/>
							<v-select
								outlined
								:label="$t('generic.lang_emailTemplates')"
								dense
								item-value="id"
								item-text="tmplName"
								return-object
								:items="emailTemplates"
								v-model="emailTmpl"
								@change="setTemplate($event)"
							>
							</v-select>
						</v-col>
						<v-col cols="12" sm="4" md="3">
							<v-checkbox
								class="ma-0"
								v-model="actions"
								:true-value="1"
								:false-value="0"
								:label="$t('eventbee.lang_cancelEvent')"
								hide-details
							></v-checkbox>
						</v-col>
						<quill-editor id="editor1" :options="quillOptions" output="html" class="mb-4" v-model="body">
						</quill-editor>
					</v-row>
				</v-form>
			</v-card-text>
			<v-card-actions class="card-footer pt-1 pb-1">
				<v-btn color="error" elevation="0" @click="visible = false">
					{{ this.$t("generic.lang_cancel") }}
				</v-btn>
				<v-spacer />
				<v-btn :loading="loading" :disabled="loading || !valid" color="success" @click="sendEmails">
					{{ this.$t("generic.lang_sendEmail") }}
					<font-awesome-icon class="mt-0 mx-1" style="font-size: 18px" :icon="['fal', 'paper-plane']" />
				</v-btn>
			</v-card-actions>
			<div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
				<vue-touch-keyboard
					v-if="touchKeyboard.visible"
					id="onScreenKeyboard"
					:accept="hideTouchKeyboard"
					:cancel="hideTouchKeyboard"
					:defaultKeySet="touchKeyboard.keySet"
					:input="touchKeyboard.input"
					:layout="touchKeyboard.layout"
					:options="touchKeyboard.options"
					class="internalWidthExpanded"
				/>
			</div>
		</v-card>
	</v-dialog>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faPaperPlane } from "@fortawesome/pro-light-svg-icons";
import mixin from "@/mixins/KeyboardMixIns";
import { ENDPOINTS } from "../../config";
import { Events } from "@/plugins/events";
import { quillEditor } from "vue-quill-editor";

library.add(faPaperPlane);

export default {
	name: "EventbeeBulkMailComponent",
	mixins: [mixin],
	components: {
		FontAwesomeIcon,
		quillEditor,
	},
	props: {
		value: Boolean,
		eventData: Object,
		planDate: String,
	},
	// Cancel event => 1
	data() {
		return {
			loading: false,
			valid: false,
			subject: null,
			emailTemplates: [],
			actions: 0,
			emailTmpl: 0,
			body: null,
			quillOptions: {
				modules: {
					toolbar: [
						["bold", "italic", "underline", "strike"],
						[{ header: [1, 2, 3, 4, 5, 6, false] }],
						[{ align: [] }],
						[{ font: [] }],
					],
				},
			},
		};
	},
	computed: {
		visible: {
			get() {
				return this.value;
			},
			set(val) {
				this.$emit("input", val);
			},
		},
	},
	methods: {
		setTemplate(e) {
			if (e && e.id !== 0) {
				this.body = e.text;
				return;
			}
			this.body = "";
		},
		loadEmailTemplates() {
			this.loading = true;
			this.axios
				.post(ENDPOINTS.EVENTBEE.SETTINGS.EMAIL.TEMPLATES.GETALL)
				.then((res) => {
					if (res.status === 200) {
						this.emailTemplates = res.data;
						this.emailTemplates.unshift({
							tmplName: this.$t("generic.lang_none"),
							id: 0,
						});
					} else {
						Events.$emit("showSnackbar", {
							message: this.$t("generic.lang_errorOccurred"),
							color: "error",
						});
					}
				})
				.catch((err) => {
					Events.$emit("showSnackbar", {
						message: this.$t("generic.lang_errorOccurred"),
						color: "error",
					});
				})
				.finally(() => {
					this.loading = false;
				});
		},
		sendEmails() {
			this.loading = true;
			this.axios
				.post(ENDPOINTS.EVENTBEE.EMAIL.SEND, {
					subject: this.subject,
					emailTemplateID: this.emailTmpl ? this.emailTmpl.id : 0,
					body: this.body,
					eventUUID: this.eventData.eventUUID,
					planUUID: this.eventData.key,
					date: this.planDate,
					action: this.actions,
				})
				.then((res) => {
					if (res.data.STATUS) {
						this.visible = false;
						this.$emit("updatePlans");
						Events.$emit("showSnackbar", {
							message: this.$t("generic.lang_ActionSuccessful"),
							color: "success",
						});
					} else {
						Events.$emit("showSnackbar", {
							message: res.data.error | this.$t("generic.lang_errorOccurred"),
							color: "error",
						});
					}
				})
				.catch((err) => {
					Events.$emit("showSnackbar", {
						message: this.$t("generic.lang_errorOccurred"),
						color: "error",
					});
				})
				.finally(() => {
					this.loading = false;
				});
		},
	},
	mounted() {
		this.loadEmailTemplates();
	},
};
</script>

<style scoped>
#editor1 {
	height: 180px;
	width: 100%;
}
</style>
