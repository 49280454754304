<template>
  <div>
    <page-title :heading="'EventBee'" :icon=icon :subheading="$t('eventbee.lang_events')"
    ></page-title>
    <div class="app-main__inner">
      <EventsComponents/>
    </div>
  </div>
</template>

<script>
import PageTitle from "../../Layout/Components/PageTitle";
import EventsComponents from "../../components/eventbee/EventsComponents";
export default {
  name: "Events",
  components: {EventsComponents, PageTitle},
  data: () => ({
    icon: 'pe-7s-ticket icon-gradient bg-tempting-azure',
  })
}
</script>

<style scoped>

</style>