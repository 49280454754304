<template>

  <div class="content mt-0 pa-0">
    <!--reservation types -->
    <div v-if="currentTab === 'tab1'">

      <v-card class="main-card mb-3 card pa-0 ma-0 border-top" outlined>

        <div class="card-body pa-0">
          <div :class="[this.$vuetify.theme.dark? 'dark-bg' : 'white']" class="card-title  card-header">
            <div class="btn-actions-pane-right actions-icon-btn">

              <div class="btn-actions-pane-right actions-icon-btn pt-2">
                <v-btn class="elevation-0 pl-2 pr-2" color="primary" @click="showCreateEvent=true">
                  <v-icon class="ml-0 pl-0">
                    add
                  </v-icon>
                  {{ this.$t('generic.lang_create') }}
                </v-btn>
              </div>
            </div>
          </div>
          <b-tabs v-model="tab" content-class="mt-3">
            <b-tab :active="this.tab===0" @click="changeTab(0)">
              <template v-slot:title>
                <font-awesome-icon :icon="['fal', 'list']" class="text-muted actions" size="2x"
                                   style="font-size: 16px"/>
                <span class="pl-2">{{ $t('generic.lang_list') }}</span>
              </template>
              <EventsListComponent v-if="this.tab===0" ref="eventsList"/>
            </b-tab>
            <b-tab :active="this.tab===1" :title="$t('generic.lang_calendar')" @click="changeTab(1)">
              <template v-slot:title>
                <font-awesome-icon :icon="['fal', 'calendar']" class="text-muted actions" size="2x"
                                   style="font-size: 16px"/>
                <span class="pl-2">{{ $t('eventbee.lang_eventPlanning') }}</span>
              </template>
              <EventsCalendarComponent ref="eventsCalendar" v-if="this.tab===1"/>
            </b-tab>
          </b-tabs>
        </div>

      </v-card>

    </div>

    <CreateEventComponent v-if="showCreateEvent" v-model="showCreateEvent" @close="showCreateEvent=false" @reloadData="refresh"/>

  </div>

</template>

<script>
import Tabs from 'vue-tabs-with-active-line';
import Calendar from "@/views/accounting/Calendar";


import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {library} from '@fortawesome/fontawesome-svg-core';
import {
  faCalendar,
  faCalendarCheck,
  faCalendarTimes,
  faGlobe,
  faList,
  faPhone,
  faPowerOff,
  faStream,
  faUserCheck,
  faUsers,
  faUserTimes
} from '@fortawesome/pro-light-svg-icons';

import {ENDPOINTS} from "@/config";
import {Events} from "@/plugins/events";

import countTo from 'vue-count-to';

import EventsListComponent from "./EventsListComponent";
import EventsCalendarComponent from "./EventsCalendarComponent";
import CreateEventComponent from "./CreateEventComponent";

library.add(faCalendar, faList, faUserCheck, faUserTimes, faUsers, faGlobe, faPhone, faPowerOff, faCalendarTimes, faCalendarCheck, faStream);

export default {
  components: {
    CreateEventComponent,
    EventsCalendarComponent,
    EventsListComponent,
    countTo,
    Calendar,
    'font-awesome-icon': FontAwesomeIcon,
    Tabs
  },
  name: "EventsComponents",
  data: () => {
    return {
      loading: false,
      showCreateEvent:false,
      reservation_enabled: false,
      currentTab: 'tab1',
      loadData: false,
      tab: 0,
      statistics: {
        canceled: 0,
        confirmed: 0,
        online: 0,
        phone: 0,
        unconfirmed: 0,
        customers: 0
      }
    }
  },
  mounted() {
  },
  computed: {
  },
  methods: {
    changeTab(tab) {
      this.tab = tab;
    },
    handleClick(newTab) {
      this.currentTab = newTab;
    },
    refresh(){
      if(this.tab===0){
        this.$refs.eventsList.getDataFromApi();
      }
    }
  }
}
</script>

<style>
.shadow-tabs {
  z-index: 1 !important;
}
</style>
