<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :return-value="time"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :value="value"
        :data-layout="dataLayout"
        @focus="$emit('focus')"
        :rules="rules"
        :label="label"
        :disabled="disabled"
        :dense="dense"
        :outlined="outlined"
        :readonly="readonly"
        :class="customClass"
        :prepend-inner-icon="prependInnerIcon"
        v-bind="attrs"
        v-on="on"
      />
    </template>

    <v-time-picker
      v-if="menu"
      v-model="time"
      :max="max"
      :min="min"
      :no-title="noTitle"
      :scrollable="scrollable"
      format="24hr"
    >
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="menu = false">
        {{ $t("generic.lang_cancel") }}
      </v-btn>
      <v-btn text color="primary" @click="$refs.menu.save(time)">
        {{ $t("generic.lang_apply") }}
      </v-btn>
    </v-time-picker>
  </v-menu>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: "",
    },
    dataLayout: {
      type: String,
      default: "normal",
    },
    rules: Array,
    customClass: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: true,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    prependInnerIcon: {
      type: String,
      default: "",
    },
    min: {
      type: String,
      default: "",
    },
    max: {
      type: String,
      default: "",
    },
    noTitle: {
      type: Boolean,
      default: false,
    },
    scrollable: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    menu: false,
  }),
  computed: {
    time: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>
