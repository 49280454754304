<template>
  <v-dialog
    fullscreen
    persistent
    scrollable
    :value="value"
  >
    <v-card
        height="100%"
        class="main-card mb-3 pa-0 ma-0"
    >
      <v-card-text class="pa-0">
        <div
          :class="[this.$vuetify.theme.dark ? 'dark-bg' : 'white']"
          class="card-title card-header-tab card-header w-100 border-bottom"
          style="position: fixed; z-index: 1000;border-bottom: 1px solid lightgray !important;"
        >
          <div
            class="card-header-title font-size-lg text-capitalize font-weight-normal w-100"
          >
            {{ edit?$t('eventbee.lang_editEvent'):$t('eventbee.lang_createEvent')}}
          </div>
          <div class="btn-actions-pane-right actions-icon-btn">
            <div class="btn-actions-pane-right actions-icon-btn">
              <v-btn
                v-if="edit"
                :disabled="loading || loadData || !valid"
                :loading="loading"
                class="elevation-0"
                color="primary"
                fab
                small
                @click="duplicateEvent"
              >
                <v-icon>mdi-content-copy</v-icon>
              </v-btn>

              <v-btn
                :disabled="loading || loadData || !valid"
                :loading="loading"
                class="elevation-0"
                color="primary"
                fab
                small
                @click="createEvent"
              >
                <v-icon>check</v-icon>
              </v-btn>

              <v-btn
                class="elevation-0"
                color="error"
                fab
                small
                @click="cancel"
              >
                <v-icon>close</v-icon>
              </v-btn>
            </div>
          </div>
          <v-divider />
        </div>

        <v-card-text class="pb-0 pt-8">
          <v-form
            ref="eventForm"
            lazy-validation
            v-model="valid"
          >
            <v-row>
              <v-col
                cols="12"
                lg="6"
                align="center"
                class="pt-8"
              >
                <div
                  :class="[this.$vuetify.theme.dark ? 'dark-bg' : '']"
                  class="main-card mb-3 card w-75 shadow-sm"
                >
                  <div class="pa-0 ma-0 dropdown-menu-header">
                    <div
                      :class="[this.$vuetify.theme.dark ? 'white--text' : '']"
                      class="card-header bg-muted text-dark"
                    >
                      {{ $t('eventbee.lang_eventImage') }}
                    </div>
                  </div>
                  <div class="card-body">
                    <image-cropper
                      ref="eventImage"
                      v-model="form.image"
                    />
                  </div>
                </div>
              </v-col>

              <v-col
                cols="12"
                lg="6"
                align="center"
                class="pt-8"
              >
                <div
                  :class="[this.$vuetify.theme.dark ? 'dark-bg' : '']"
                  class="main-card mb-3 card w-75 shadow-sm"
                >
                  <div class="pa-0 ma-0 dropdown-menu-header">
                    <div
                      :class="[this.$vuetify.theme.dark ? 'white--text' : '']"
                      class="card-header bg-muted text-dark"
                    >
                      {{ $t('eventbee.lang_directionMap') }}
                    </div>
                  </div>
                  <div class="card-body">
                    <image-cropper
                      ref="directionMap"
                      v-model="form.directionMap"
                    />
                  </div>
                </div>
              </v-col>

              <v-col
                cols="4"
              >
                <v-text-field
                  v-model="form.eventName"
                  :data-layout="KEYBOARD.KEYSETS.NORMAL"
                  :disabled="loadData"
                  :label="$t('generic.lang_name')"
                  outlined
                  dense
                  :rules="[
                    (v) =>
                      (!!v && v.length > 6 && v.length < 70) ||
                      $t('generic.lang_numberOfCharactersShouldBeBetween') +
                        ' 6 ' +
                        $t('generic.lang_and') +
                        ' 70',
                  ]"
                  counter
                  @focus="showTouchKeyboard"
                />
              </v-col>
              <v-col
                cols="4"
              >
                <v-select
                  outlined
                  :label="$t('eventbee.lang_eventGroup')"
                  item-value="id"
                  item-text="name"
                  dense
                  :rules="[rules.required]"
                  :items="eventGroups"
                  v-model="form.groupID"
                >
                </v-select>
              </v-col>

              <v-col cols="4">
                <v-autocomplete
                    :filter="itemsFilter"
                    clearable
                    hide-details
                    outlined
                    dense
                    flat
                    class="mx-1"
                    :loading="searchingItems"
                    type="text"
                    v-model="form.itemId"
                    :rules="[rules.required]"
                    :items="items"
                    item-text="name"
                    item-value="id"
                    @keyup="searchItem"
                    :label="$t('generic.lang_used_for_ticketsale')"
                >
                  <template v-slot:prepend-item>
                    <v-list-item :disabled="isHybrid">
                      <v-list-item-content @click="showCreateItemDialog = true">
                        <v-list-item-title>
                          <p style="cursor: pointer">
                            {{ $t('generic.lang_addItem') }}
                          </p>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </v-col>

              <v-col
                class="pt-0 pb-0 mt-0 mb-0 d-flex align-self-center"
                cols="6"
                sm="4"
                lg="3"
md="3"
                xl="2"
              >
                <v-radio-group
                  :loading="loadData"
                  row
                  :rules="[rules.required]"
                  v-model="form.eventType"
                  hide-details
                >
                  <div class="d-flex mr-3">
                    <v-radio
                      :label="$t('eventbee.lang_entryTicket')"
                      value="0"
                      class="ma-0 mr-1"
                    />

                    <v-tooltip top nudge-bottom="20" nudge-left="100">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon color="primary" dark size="20" v-bind="attrs" v-on="on">
                          mdi-information
                        </v-icon>
                      </template>
                      <span>
                        {{ $t("eventbee.lang_entryTicketTooltip") }}
                      </span>
                    </v-tooltip>
                  </div>

                  <div class="d-flex">
                    <v-radio
                      :label="$t('eventbee.lang_eventTicket')"
                      value="1"
                      class="ma-0 mr-1"
                    />

                    <v-tooltip top nudge-bottom="20" nudge-left="100">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon color="primary" dark size="20" v-bind="attrs" v-on="on">
                          mdi-information
                        </v-icon>
                      </template>
                      <span>
                        {{ $t("eventbee.lang_eventTicketTooltip") }}
                      </span>
                    </v-tooltip>
                  </div>
                </v-radio-group>
              </v-col>  
              <v-col
                class="pt-0 pb-0 mt-0 mb-0 d-flex align-self-center"
                cols="6"
                sm="4"
                lg="3"
md="3"
                xl="2"
              >
                <div class="d-flex">
                  <v-switch
                    v-model="form.eventFeat"
                    
                    inset
                    :label="$t('eventbee.lang_featured')"
                    outlined
                    dense
                  ></v-switch>

                  <v-tooltip top nudge-bottom="20" nudge-left="100">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="primary" dark size="20" v-bind="attrs" v-on="on">
                        mdi-information
                      </v-icon>
                    </template>
                    <span>
                      {{ $t("eventbee.lang_featuredTooltip") }}
                    </span>
                  </v-tooltip>
                </div>
                </v-col>
                <v-col
                class="pt-0 pb-0 mt-0 mb-0 d-flex align-self-center"
                cols="6"
                sm="4"
                lg="3"
md="3"
                xl="2"
                >
                <div class="d-flex">
                  <v-switch
                    :true-value="true"
                    :false-value="false"
                    v-model="form.isBookableSameDay"
                    
                    inset
                    :label="$t('eventbee.lang_isBookableSameDay')"
                    outlined
                    dense
                  ></v-switch>

                  <v-tooltip top nudge-bottom="20" nudge-left="100">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="primary" dark size="20" v-bind="attrs" v-on="on">
                        mdi-information
                      </v-icon>
                    </template>
                    <span>
                      {{ $t("eventbee.lang_isBookableSameDayTooltip") }}
                    </span>
                  </v-tooltip>
                </div>
              </v-col>
                <v-col
                class="pt-0 pb-0 mt-0 mb-0 d-flex align-self-center"
                cols="6"
                sm="4"
                lg="3"
md="3"
                xl="2"
                >
                <div class="d-flex">
                  <v-switch
                    :true-value="true"
                    :false-value="false"
                    v-model="form.personalizeTicket"
                    
                    inset
                    :label="$t('eventbee.lang_personalizeTicket')"
                    outlined
                    dense
                  ></v-switch>

                  <v-tooltip top nudge-bottom="20" nudge-left="100">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="primary" dark size="20" v-bind="attrs" v-on="on">
                        mdi-information
                      </v-icon>
                    </template>
                    <span>
                      {{ $t("eventbee.lang_personalizeTicketTooltip") }}
                    </span>
                  </v-tooltip>
                </div>
              </v-col>
                <v-col
                class="pt-0 pb-0 mt-0 mb-0 d-flex align-self-center"
                cols="6"
                sm="4"
                lg="3"
md="3"
                xl="2"
                >
                <div class="d-flex">
                  <v-switch
                    v-model="form.displayEventInKiosk"
                    
                    inset
                    :label="$t('eventbee.lang_displayEventInKiosk')"
                    outlined
                    dense
                  ></v-switch>

                  <v-tooltip top nudge-bottom="20" nudge-left="100">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="primary" dark size="20" v-bind="attrs" v-on="on">
                        mdi-information
                      </v-icon>
                    </template>
                    <span>
                      {{ $t("eventbee.lang_displayEventInKioskTooltip") }}
                    </span>
                  </v-tooltip>
                </div>
              </v-col>
                <v-col
                class="pt-0 pb-0 mt-0 mb-0 d-flex align-self-center"
                cols="6"
                sm="4"
                lg="3"
md="3"
                xl="2"
                >
                <div class="d-flex">
                  <v-switch
                    v-model="form.displayEventInWebshop"
                    
                    inset
                    :label="$t('eventbee.lang_displayInWebshop')"
                    outlined
                    dense
                  ></v-switch>

                  <v-tooltip top nudge-bottom="20" nudge-left="100">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="primary" dark size="20" v-bind="attrs" v-on="on">
                        mdi-information
                      </v-icon>
                    </template>
                    <span>
                      {{ $t("eventbee.lang_displayEventInWebshopTooltip") }}
                    </span>
                  </v-tooltip>
                </div>
              </v-col>
                <v-col
                class="pt-0 pb-0 mt-0 mb-0 d-flex align-self-center"
                cols="6"
                sm="4"
                lg="3"
md="3"
                xl="2"
                >
                <div class="d-flex">
                  <v-switch
                    v-model="form.displayEventInPos"
                    
                    inset
                    :label="$t('eventbee.lang_displayInPos')"
                    outlined
                    dense
                  ></v-switch>

                  <v-tooltip top nudge-bottom="20" nudge-left="100">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="primary" dark size="20" v-bind="attrs" v-on="on">
                        mdi-information
                      </v-icon>
                    </template>
                    <span>
                      {{ $t("eventbee.lang_displayEventInPosTooltip") }}
                    </span>
                  </v-tooltip>
                </div>
              </v-col>
              <v-col
                cols="12"
                class="px-0"
              >
                <v-card-text>
                  <TicketsPriceListComponent v-model="form.priceList" />
                </v-card-text>
              </v-col>
              <v-col
                class="pt-0 pb-0 mt-0 mb-0"
                cols="12"
                sm="6"
                md="6"
                lg="6"
                xl="6"
              >
                <v-text-field
                  v-model="form.subtitle"
                  :data-layout="KEYBOARD.KEYSETS.NORMAL"
                  :disabled="loadData"
                  :label="$t('generic.lang_title')"
                  outlined
                  dense
                  :rules="[
                    (v) =>
                      (!!v && v.length <= 100) ||
                      $t('generic.lang_numberOfCharactersShouldBeBetween') +
                        ' 1 ' +
                        $t('generic.lang_and') +
                        ' 100',
                  ]"
                  @focus="showTouchKeyboard"
                />
              </v-col>

              <v-col
                class="pt-0 pb-0 mt-0 mb-0"
                cols="12"
                sm="6"
                md="6"
                lg="6"
                xl="6"
              >
                <v-textarea
                  :hint="
                    $t('generic.lang_numberOfCharactersShouldBeBetween') +
                    ' 0 ' +
                    $t('generic.lang_and') +
                    ' 150'
                  "
                  v-model="form.shortDescription"
                  :data-layout="KEYBOARD.KEYSETS.NORMAL"
                  :disabled="loadData"
                  :label="$t('generic.lang_shortDescription')"
                  outlined
                  hide-details
                  dense
                  rows="3"
                  @focus="showTouchKeyboard"
                />
              </v-col>

              <v-col
                class=""
                style="height: 350px !important"
                cols="12"
                sm="12"
              >
                <v-subheader
                  ><strong>{{
                    $t('generic.lang_description')
                  }}</strong></v-subheader
                >
                <quill-editor
                  id="editor2"
                  :data-layout="KEYBOARD.KEYSETS.NORMAL"
                  :options="quillOptions"
                  @focus="showTouchKeyboard"
                  output="html"
                  v-model="form.description"
                >
                </quill-editor>
              </v-col>
              <v-col
                class=""
                style="height: 350px !important"
                cols="12"
                sm="12"
              >
                <v-subheader class="d-flex justify-space-between">
                  <strong>{{ $t('eventbee.lang_eventInfo') }}</strong>
                  <v-switch
                    v-model="form.eventInfoStatus"
                    dense
                    hide-details
                    inset
                    class="ma-0 mr-n4 pa-0"
                    :label="$t('generic.lang_enable')"
                  >
                  </v-switch>
                </v-subheader>
                <quill-editor
                  id="editor1"
                  :data-layout="KEYBOARD.KEYSETS.NORMAL"
                  :options="quillOptions"
                  @focus="showTouchKeyboard"
                  output="html"
                  v-model="form.eventInfo"
                >
                </quill-editor>
              </v-col>
              <v-col
                class=""
                cols="6"
              >
                <v-select
                  outlined
                  :label="$t('generic.lang_alertColor')"
                  dense
                  item-value="name"
                  item-text="name"
                  :color="form.warning.color"
                  :items="colors"
                  chips
                  clearable
                  v-model="form.warning.color"
                >
                  <template v-slot:selection="{ item }">
                    <v-chip
                      dark
                      :color="item.name"
                      small
                    >
                    </v-chip>
                    {{ item.name }}
                  </template>
                  <template v-slot:item="{ item, attrs, on }">
                    <v-list-item
                      v-on="on"
                      v-bind="attrs"
                    >
                      <v-list-item-content>
                        <v-list-item-title>
                          <v-row
                            no-gutters
                            align="center"
                          >
                            <v-chip
                              :color="item.name"
                              small
                            ></v-chip>
                            <span class="ml-2">{{ item.name }}</span>
                          </v-row>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-select>
                <v-textarea
                  :data-layout="KEYBOARD.KEYSETS.NORMAL"
                  :label="$t('generic.lang_alertBody')"
                  outlined
                  @focus="showTouchKeyboard"
                  rows="7"
                  hide-details
                  dense
                  v-model="form.warning.text"
                >
                  <template v-slot:append>
                    <v-switch
                      v-model="form.warning.status"
                      dense
                      hide-details
                      inset
                      class="ma-0 mr-n4 pa-0"
                    >
                    </v-switch>
                  </template>
                </v-textarea>
              </v-col>

              <v-col
                class=""
                cols="6"
              >
                <v-textarea
                  :data-layout="KEYBOARD.KEYSETS.NORMAL"
                  :label="
                    $t('generic.lang_event') + ' ' + $t('generic.lang_location')
                  "
                  outlined
                  @focus="showTouchKeyboard"
                  rows="7"
                  dense
                  v-model="form.eventLocation"
                >
                  <template v-slot:append>
                    <v-switch
                      v-model="form.eventLocationStatus"
                      dense
                      hide-details
                      inset
                      class="ma-0 mr-n4 pa-0"
                    >
                    </v-switch>
                  </template>
                </v-textarea>

                <v-select
                  outlined
                  :label="$t('generic.lang_answerEmailTemplates')"
                  dense
                  item-value="id"
                  item-text="tmplName"
                  :items="emailTemplates"
                  v-model="form.answerEmailTemplateID"
                >
                </v-select>
              </v-col>
              <v-col
                class=""
                cols="6"
              >
                <v-select
                  outlined
                  :label="$t('eventbee.lang_refundPolicy')"
                  dense
                  item-value="id"
                  item-text="name"
                  :items="refundPolicies"
                  v-model="form.refundPolicyID"
                  hide-details
                >
                </v-select>
              </v-col>
              <v-col cols="6"/>
              <v-col cols="6">
                <v-select
                    v-model="selectedAttributes"
                    outlined
                    :label="$t('eventbee.lang_attributeGroups')"
                    dense
                    clearable
                    item-value="uuid"
                    item-text="name"
                    multiple
                    :items="attributeGroups"
                    return-object
                    @change="updateAttributes"
                >
                </v-select>
              </v-col>
              <v-col
                class=""
                cols="6"
              >
                <v-card v-if="sortedAttributes && sortedAttributes.length>0" outlined>
                  <v-card-title>
                    {{$t('generic.lang_attributesOrder')}}
                  </v-card-title>
                  <v-divider class="ma-0"/>
                  <draggable
                      :list="sortedAttributes"
                      v-bind="dragOptions"
                  >
                    <v-list-item v-for="attribute in sortedAttributes" :key="attribute.uuid" class="border-bottom">
                      <v-list-item-icon>
                        <font-awesome-icon :icon="['fas','grip-vertical']" style="color:lightgray;cursor: pointer;"
                                           class="page__grab-icon text-lighter handle"/>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ attribute.name }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </draggable>
                </v-card>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card-text>

      <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
        <vue-touch-keyboard
          v-if="touchKeyboard.visible"
          id="onScreenKeyboard"
          :accept="hideTouchKeyboard"
          :cancel="hideTouchKeyboard"
          :defaultKeySet="touchKeyboard.keySet"
          :input="touchKeyboard.input"
          :layout="touchKeyboard.layout"
          :options="touchKeyboard.options"
          class="internalWidthExpanded"
        />
      </div>
    </v-card>

    <CreateItemDialog
      v-if="showCreateItemDialog"
      :show-dialog="showCreateItemDialog"
      @close="showCreateItemDialog = false"
      @selectItem="onItemCreated"
    />
  </v-dialog>
</template>

<script>
import { ENDPOINTS } from '@/config';
import { Events } from '@/plugins/events';
import ImageCropper from '@/components/common/imagecropper';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCalendarAlt, faHashtag, faGripVertical } from '@fortawesome/pro-light-svg-icons';
import draggable from "vuedraggable";
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import mixin from '@/mixins/KeyboardMixIns';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import { quillEditor } from 'vue-quill-editor';
import CreateItemDialog from '@/components/billing/CreateItemDialog';
import validation from '../../mixins/validation/validation';
import TicketsPriceListComponent from "./TicketsPriceListComponent";


library.add(faCalendarAlt, faHashtag, faGripVertical);

export default {
  name: 'CreateEventComponent',
  props: {
    value: {
      type: Boolean,
      value: false,
      default: false,
    },
    edit: {
      type: Boolean,
      value: false,
    },
    uuid: {
      type: String,
      required: false,
    },
  },
  mixins: [mixin,validation],
  components: {
    TicketsPriceListComponent,
    ImageCropper,
    'font-awesome-icon': FontAwesomeIcon,
    quillEditor,
    CreateItemDialog,
    draggable
  },
  computed: {
    isHybrid() {
      return process.env.VUE_APP_HYBRID === 'true' || this.$store.getters["permissions/checkHybridApp"];
    },
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    },
  },
  data() {
    return {
      awaitingSearch: null,
      items: [],
      searchingItems: false,
      showCreateItemDialog: false,
      loading: false,
      loadData: false,
      attributeGroups: [],
      selectedAttributes: [],
      sortedAttributes: [],
      eventGroups: [],
      refundPolicies: [],
      emailTemplates: [],
      valid: false,
      duplicationData: {},
      form: {
        itemId: null,
        attributeGroupUUID: null,
        attributeGroups: [],
        image: null,
        directionMap: null,
        eventType: '0',
        eventName: null,
        eventFeat: null,
        displayEventInKiosk: true,
        displayEventInWebshop: true,
        displayEventInPos: true,
        subtitle: null,
        shortDescription: null,
        description: null,
        eventLocation: null,
        eventLocationStatus: true,
        eventInfo: null,
        eventInfoStatus: true,
        answerEmailTemplateID: 0,
        refundPolicyID: 0,
        isBookableSameDay: false,
        personalizeTicket: false,
        warning: {
          text: null,
          color: 'warning',
          status: true,
        },
        priceList: [],
      },
      quillOptions: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ align: [] }],
            [{ font: [] }],
            [
              { list: 'ordered' },
              { list: 'bullet' },
              { indent: '-1' },
              { indent: '+1' },
            ],
          ],
        },
      },
      colors: [
        { name: 'primary' },
        { name: 'purple' },
        { name: 'success' },
        { name: 'error' },
        { name: 'warning' },
      ],
    };
  },
  watch: {
    value(val) {
      if (val && this.edit) {
        this.getEvent();
      } else if (!val) {
        if (this.$refs.eventForm) this.$refs.eventForm.reset();
        if (this.$refs.directionMap) this.$refs.directionMap.reset();
        this.form.image = null;
        this.form.directionMap = null;
        this.items = [];
      }
    },
  },
  mounted() {
    this.loadEmailTemplates();
    this.loadEventGroups();
    this.loadAttributeGroups();
    this.loadRefundPolicies();

    //if (this.$refs.eventForm) this.$refs.eventForm.reset();
  },
  methods: {
    updateAttributes(e){
      this.sortedAttributes = [];
      e.forEach(((item, index)=>{
        this.sortedAttributes.push({
          uuid: item.uuid,
          name:  item.name,
          position: index,
        })
      }))
    },
    itemsFilter(item, queryText) {
      return (
        item.name?.toLowerCase().includes(queryText?.toLowerCase()) ||
        item.ean?.toLowerCase().includes(queryText?.toLowerCase())
      );
    },
    onItemCreated(item) {
      this.searchItem({ target: { value: item.name } });
      this.form.itemId = item.id;
    },
    searchItem(event) {
      if(!event.target.value)
        return;
      clearTimeout(this.awaitingSearch);
      this.awaitingSearch = setTimeout(() => {
        this.items = [];
        this.searchingItems = true;
        this.axios
          .post(ENDPOINTS.ERP.ITEM.SEARCH2, { query: event.target.value })
          .then((res) => {
            this.items = res.data || [];
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            this.searchingItems = false;
          });
      }, 500);
    },
    reloadData() {
      this.$emit('reloadData');
    },
    getEvent() {
      this.loading = true;
      this.loadData = true;

      this.selectedAttributes = [];
      this.sortedAttributes = [];
      this.axios
        .post(ENDPOINTS.EVENTBEE.EVENT.GET, {
          UUID: this.uuid,
          all: true,
        })
        .then(async (res) => {
          if (res.data.status) {
            this.selectedAttributes = [];
            this.sortedAttributes = [];
            this.duplicationData = Object.assign(this.duplicationData, res.data.data);
            this.form = Object.assign(this.form, res.data.data);
            if (this.form.image && this.form.image.length < 20) {
              this.form.image = null;
            }

            if (this.form.directionMap && this.form.directionMap.length < 20) {
              this.form.directionMap = null;
            }

            this.form.eventType = this.form.eventType.toString();

            if(this.form.attributeGroups && Array.isArray(this.form.attributeGroups) && this.attributeGroups && Array.isArray(this.form.attributeGroups)){
              this.sortedAttributes = this.attributeGroups.filter(item => this.form.attributeGroups.some(value => value.uuid === item.uuid)).map((item,index) => ({uuid: item.uuid, name: item.name, position: index}));
              this.sortedAttributes = _.orderBy(this.sortedAttributes, 'position', 'asc');
              this.selectedAttributes = this.attributeGroups.filter(item => this.form.attributeGroups.some(value => value.uuid === item.uuid));
            }

            if (this.form.itemId) {
              let itemId = await this.$store.dispatch("items/getItemByID", this.form.itemId);
              if (itemId) {
                this.items.push(itemId);
              }
            }
          } else {
            Events.$emit('showSnackbar', {
              message: this.$t('generic.lang_errorOccurred'),
              color: 'error',
            });
          }
        })
        .catch((err) => {
          Events.$emit('showSnackbar', {
            message: this.$t('generic.lang_errorOccurred'),
            color: 'error',
          });
        })
        .finally(() => {
          this.loading = false;
          this.loadData = false;
        });
    },
    loadEmailTemplates() {
      this.loading = true; 
      this.axios
        .post(ENDPOINTS.EVENTBEE.SETTINGS.EMAIL.TEMPLATES.GETALL)
        .then((res) => {
          if (res.status === 200) {
            this.emailTemplates = res.data;
            this.emailTemplates.unshift({
              tmplName: 'Kein',
              id: 0,
            });
          } else {
            Events.$emit('showSnackbar', {
              message: this.$t('generic.lang_errorOccurred'),
              color: 'error',
            });
          }
        })
        .catch((err) => {
          Events.$emit('showSnackbar', {
            message: this.$t('generic.lang_errorOccurred'),
            color: 'error',
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    loadEventGroups() {
      this.loading = true;
      this.axios
        .post(ENDPOINTS.EVENTBEE.SETTINGS.EVENTGROUPS.GET)
        .then((res) => {
          if (res.data.status) {
            this.eventGroups = res.data.data;
          } else {
            Events.$emit('showSnackbar', {
              message: this.$t('generic.lang_errorOccurred'),
              color: 'error',
            });
          }
        })
        .catch((err) => {
          Events.$emit('showSnackbar', {
            message: this.$t('generic.lang_errorOccurred'),
            color: 'error',
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    loadAttributeGroups() {
      this.loading = true;
      this.axios
        .post(ENDPOINTS.EVENTBEE.SETTINGS.ATTRIBUTEGROUP.GET)
        .then((res) => {
          if(!res.data.status){
            this.attributeGroups = res.data;
          }else {
            Events.$emit('showSnackbar', {
              message: res.data.status,
              color: 'error',
            });  
          }
        })
        .catch((err) => {
          Events.$emit('showSnackbar', {
            message: this.$t('generic.lang_errorOccurred'),
            color: 'error',
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    loadRefundPolicies() {
      this.loading = true;
      this.axios
        .post(ENDPOINTS.EVENTBEE.SETTINGS.REFUNDPOLICIES.GET)
        .then((res) => {
          if (res.status === 200) {
            this.refundPolicies = res.data;
            this.refundPolicies.unshift({
              name: 'Kein',
              id: 0,
            });
          } else {
            Events.$emit('showSnackbar', {
              message: this.$t('generic.lang_errorOccurred'),
              color: 'error',
            });
          }
        })
        .catch((err) => {
          Events.$emit('showSnackbar', {
            message: this.$t('generic.lang_errorOccurred'),
            color: 'error',
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    createEvent() {
      let endpoint = this.edit
        ? ENDPOINTS.EVENTBEE.EVENT.UPDATE
        : ENDPOINTS.EVENTBEE.EVENT.CREATE;

      if (this.$refs.eventImage.getCroppedCanvas() !== null)
        this.$refs.eventImage.cropImage();

      if (this.$refs.directionMap.getCroppedCanvas() !== null)
        this.$refs.directionMap.cropImage();

      if (!this.$refs.eventForm.validate()) {
        return;
      }

      if (this.form.priceList && this.form.priceList.length < 1) {
        Events.$emit('showSnackbar', {
          message: this.$t('eventbee.lang_AddAtLeastPriceSegment'),
          color: 'error',
        });

        return;
      } else if (this.isIssetArr(this.form.priceList)) {
        this.form.priceList = this.form.priceList.map((price) => {
          price.id = price.priceSegmentID;
          price.max = price.max || price.max===0 ? price.max:0;
          return price;
        });
      }

      this.form.attributeGroups = this.sortedAttributes.map(item => ({uuid: item.uuid, position: item.position}));
      this.loadData = true;

      this.axios
        .post(endpoint, this.form)
        .then((res) => {
          if (res.data.STATUS) {
            Events.$emit('showSnackbar', {
              message: this.$t('generic.lang_success'),
              color: 'success',
            });
            this.reloadData();
            this.cancel();
          }
        })
        .catch((err) => {
          Events.$emit('showSnackbar', {
            message: this.$t('generic.lang_errorOccurred'),
            color: 'error',
          });
        })
        .finally(() => {
          this.loadData = false;
        });
    },
    duplicateEvent() {
      this.$swal({
        title: this.$t('eventbee.lang_duplicateEventTitle'),
        text: this.$t('eventbee.lang_duplicateEventText'),
        icon: "warning",
        cancelButtonText: this.$t('generic.lang_no'),
        confirmButtonText: this.$t('generic.lang_yes'),
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          this.loadData = true;

          this.axios
            .post(ENDPOINTS.EVENTBEE.EVENT.CREATE, this.duplicationData)
            .then((res) => {
              if (res.data.STATUS) {
                Events.$emit('showSnackbar', {
                  message: this.$t('generic.lang_success'),
                  color: 'success',
                });
                this.reloadData();
                this.cancel();
              }
            })
            .catch((err) => {
              Events.$emit('showSnackbar', {
                message: this.$t('generic.lang_errorOccurred'),
                color: 'error',
              });
            })
            .finally(() => {
              this.loadData = false;
            });
        },
        allowOutsideClick: () => !this.$swal.isLoading,
      });
    },
    cancel() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped>
#editor1,
#editor2 {
  height: 250px;
  width: 100%;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
</style>
